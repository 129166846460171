function initTeamDisplay()
{

	var member = getUrlParameter('member');
	console.log(member);

	if(!(!!member))
	{
		jQuery('.member-cat').each(function(){
			jQuery(this).children().children('.member-row').first().show();
		});
		jQuery('.member-list').each(function(){
			jQuery(this).children('.member-list-item').first().addClass('active');
		});
		

		var interval = setInterval(memberTimer,10000);
	}
	else
	{
		jQuery('.member-list-item').each(function(){
			if(jQuery(this).data('title')===member)
			{
				jQuery(this).addClass('active');
			}
		});
		jQuery('.member-row').each(function(){
			if(jQuery(this).data('title')===member)
			{
				jQuery(this).show();
			}
		});
	}
	jQuery('.member-list-item').click(function(e){
			clearInterval(interval);
			actionLink(jQuery(this));
		});



}
function memberTimer()
{
		jQuery('.member-list').each(function(){
			jQuery(this).children().each(function(){
				if(jQuery(this).hasClass('active'))
				{
					var next = jQuery(this).next();
					console.log('Next is ' + next.attr('id'));
					if(typeof next.attr('id') == "undefined")
					{
						actionLink(jQuery(this).parent().children().first());
					}
					else
					{
						actionLink(next);
					}
					return false;
				}
			});
		});
}
function actionLink(obj)
{
	obj.parent().children('.member-list-item').each(function(){
			if(jQuery(this).hasClass('active'))
			{
				jQuery(this).removeClass('active')
			}
		});
	
		obj.addClass('active');
		console.log(obj.data('title'));
		var id = obj.attr('id').replace("member-","");
		toggleMember('#display-' + id);
}
function toggleMember(id)
{
	jQuery(id).parent().children('.member-row').hide();
	jQuery(id).show();
}

var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
};

initTeamDisplay();