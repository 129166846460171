jQuery(document).ready(function(){

	var width = jQuery(window).width();

	 var url = jQuery('.footer-image').data('background');
 	var url2 = jQuery(".rossouws-background").data("background");
 	var mobile = jQuery(".rossouws-background").data("mobile");

	if(width > 768)
	{

		  console.log('Enabled parallax');
		 jQuery('.footer-image').parallax({imageSrc: url});
		  jQuery('.rossouws-background').parallax({imageSrc: url2});
	}
	else
	{
		console.log('Disabled parallax');
		jQuery('.footer-image').css('background-image',"url('"+url+"')");
		jQuery('.rossouws-background').css('background-image',"url('"+mobile+"')");
	}
});

